import { getUser } from "../services/UserService";
import { getPlots } from "../services/LandsService";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Table, Input } from "react-daisyui";
import SelectDropdownComponent from "../components/SelectDropdownComponent";
import { getStates, getDivisions, getDivisionHierarchy } from "../services/LocationsService";
import NavbarComponent from "../components/NavbarComponent";
import { useNavigate } from "react-router-dom";
import OverlayComponent from "../components/OverlayComponent";
import { isPhoneNumber } from "../utils/validators";
import { getSeller } from "../services/UserService";


export default function PlotsDashboardPage() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [plots, setPlots] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [resultsCount, setResultsCount] = useState(null);

  const [sellerSearchFilter, setSellerSearchFilter] = useState('');
  const [sellerSearchIsValid, setSellerSearchIsValid] = useState(false);
  const [sellerAccount, setSellerAccount] = useState({ 'id': '', 'name': '', 'phone_number': '' })

  const [divisionHierarchy, setDivisionHierarchy] = useState([]); // ["district", "mandal", "village"]
  const [divisionOptions, setDivisionOptions] = useState({}); // { 'states': [], 'districts': [], 'mandals': [], 'villages': [] }

  const exposureTypeOptions = [{ 'id': 'public', 'name': 'Public' }, { 'id': 'private', 'name': 'Private' }, { 'id': 'super_private', 'name': 'Super Private' }, { 'id': 'sweet_deal', 'name': 'Sweet Deal' }];
  const statusOptions = [{ 'id': 'pending', 'name': 'Pending' }, { 'id': 'active', 'name': 'Active' }, { 'id': 'sold', 'name': 'Sold' }, { 'id': 'expired', 'name': 'Expired' }, { 'id': 'private', 'name': 'Private' }];
  const chanceOptions = [{ 'id': true, 'name': 'Yes' }, { 'id': false, 'name': 'No' }];

  const [divisionFilter, setDivisionFilter] = useState([]); // [id, id, id]
  const [statusFilter, setStatusFilter] = useState("0");
  const [exposureTypeFilter, setExposureTypeFilter] = useState("0");
  const [isChanceFilter, setIsChanceFilter] = useState("0");
  const [isBookmarkPremiumFilter, setIsBookmarkPremiumFilter] = useState(0);
  const [isBookmarkChanceFilter, setIsBookmarkChanceFilter] = useState(0);
  const [isBookmarkVerificationFilter, setIsBookmarkVerificationFilter] = useState(0);
  const [isBookmarkInvestorsFilter, setIsBookmarkInvestorsFilter] = useState(0);



  useEffect(() => {
    fetchUser();
    fetchLands();
    fetchStates();
  }, [navigate]);

  const fetchUser = async () => {
    const userData = (state && state.hasOwnProperty("user")) ? state["user"] : await getUser();
    setUser(userData);
  }
  window.history.replaceState({}, document.title)

  const sellerPhoneChangeHandler = async (event) => {
    setSellerSearchFilter(event.target.value);
  }
  useEffect(() => {
    if (isPhoneNumber(sellerSearchFilter)) {
      setSellerSearchIsValid(true);
    } else {
      setSellerSearchIsValid(false);
    }
  }, [sellerSearchFilter]);

  const sellerSearchHandler = async (event) => {
    try {
      const data = await getSeller({ 'phone_number': '91' + sellerSearchFilter })
      if (data) {
        setSellerAccount(() => ({ 'id': data.id, 'name': data.name, 'phone_number': data.phone_number }));
      } else {
        setSellerAccount(() => ({ 'id': '', 'name': '', 'phone_number': '' }))
      }
    } catch (error) {
      setSellerAccount(() => ({ 'id': '', 'name': '', 'phone_number': '' }))
    }
  };

  const displayPrice = (pricePerSquareYard) => {
    // Format pricePerSquareYard with commas for Indian numbering system
    const formattedPrice = pricePerSquareYard.toLocaleString('en-IN');

    const priceInThousands = pricePerSquareYard / 1000; // Convert price to thousands
    if (priceInThousands > 100) {
      const lakhs = Math.floor(priceInThousands / 100);
      const thousands = (priceInThousands % 100).toFixed(2);
      let thousandsString = parseFloat(thousands).toString(); // Convert to float and then back to string to remove trailing zeros
      thousandsString = thousandsString.endsWith('.00') ? thousandsString.slice(0, -3) : thousandsString; // Remove .00 if present
      let result = `${lakhs} lakh`;
      if (thousandsString > 0) {
        result += ` ${thousandsString} thousand`;
      }
      return `${formattedPrice}`;
      // return `${formattedPrice} (${result})`;
    } else {
      let thousandsString = priceInThousands.toFixed(2);
      thousandsString = parseFloat(thousandsString).toString(); // Convert to float and then back to string to remove trailing zeros
      thousandsString = thousandsString.endsWith('.00') ? thousandsString.slice(0, -3) : thousandsString; // Remove .00 if present
      return `${formattedPrice}`;
    }
  }

  const displayPlotSize = (plotSizeSquareYards) => {
    return `${plotSizeSquareYards} (Sq. Yd)`;
  }

  const fetchStates = async () => {
    const data = await getStates();
    const options = data.results.map((stateObj) => ({ 'id': stateObj.id, 'name': stateObj.name, 'slug': stateObj.slug }));
    setDivisionOptions(() => ({ 'states': options }));
  }

  const fetchDivisionOptions = async (parentDivisionId, divisionType) => {
    // fetch divisions based on divisionType and parentDivisionId
    const data = await getDivisions({
      'parent_id': parentDivisionId,
      'division_type': divisionType
    });

    const nextDivision = divisionType;
    const index = divisionHierarchy.indexOf(divisionType);
    setDivisionOptions(prev => {
      const newOptions = {
        ...prev,
        [nextDivision]: data.results || [],
        // Clear all subsequent division options
        ...divisionHierarchy.slice(index + 1).reduce((acc, div) => ({ ...acc, [div]: [] }), {})
      }

      return newOptions;
    });
    // return data;
  }

  const fetchDivisionHierarchy = async (stateSlug) => {
    const data = await getDivisionHierarchy({
      'state_slug': stateSlug
    });
    setDivisionHierarchy(data)
    return data;
  }

  const divisionOnChangeHandler = async (division, index, newValue) => {
    const castedNewValue = parseInt(newValue);
    if (castedNewValue === 0) {
      setDivisionHierarchy([])
      setDivisionFilter([])
      // clear all the division options except states 
      setDivisionOptions({ 'states': divisionOptions.states })
    } else {
      setDivisionFilter((prev) => {
        const newFilter = [...prev.slice(0, index), castedNewValue];
        // Clear subsequent divisions when a higher-level division changes
        return newFilter;
      });

      // Fetch new options for the next division level
      if (index === 0) {
        // If state changed, fetch new division hierarchy and district options
        const stateSlug = divisionOptions.states.find(state => state.id === castedNewValue)?.slug;
        if (stateSlug) {
          const newHierarchy = await fetchDivisionHierarchy(stateSlug);
          setDivisionHierarchy(newHierarchy);

          if (newHierarchy.length > 0) {
            fetchDivisionOptions(castedNewValue, newHierarchy[0]);
          }
        }
      } else {
        // For other levels, fetch options for the next level
        const nextDivision = divisionHierarchy[index];
        console.log("nextDivision", nextDivision)
        if (nextDivision) {
          await fetchDivisionOptions(castedNewValue, nextDivision);
        }
      }
    }
  };

  const fetchLands = async () => {
    const data = await getPlots();
    console.log(data)
    setResultsCount(data.count);
    setNextPage(data.next);
    setPrevPage(data.previous);

    const plots = data.results.map((plot) =>
    ({
      ...plot,
      bookmark: bookmark_column(plot.plot_bookmark_data)

    }))
    setPlots(plots);

  };




  const statusChangeHandler = async (newValue) => {
    setStatusFilter(() => newValue);
  }
  const exposureTypeChangeHandler = async (newValue) => {
    setExposureTypeFilter(() => newValue);
  }

  const isChanceChangeHandler = async (newValue) => {
    setIsChanceFilter(() => newValue);
  }

  const bookmark_column = (data) => {
    let book_mark_array = []
    if (data?.one_acre_premium) {
      book_mark_array.push(" " + "Premium")
    }

    if (data?.chance) {
      book_mark_array.push(" " + "Chance")
    }

    if (data?.site_verification) {
      book_mark_array.push(" " + "Verification")
    }
    if (data?.investors) {
      book_mark_array.push(" " + "Investors")
    }
    console.log("bookmark array", book_mark_array.toString())
    return book_mark_array.toString();

  }

  const isBookmarkPremiumChangeHandler = async (newValue) => {
    setIsBookmarkPremiumFilter(() => newValue);

  }

  const isBookmarkChanceChangeHandler = async (newValue) => {
    setIsBookmarkChanceFilter(() => newValue);
  }

  const isBookmarkVerificationChangeHandler = async (newValue) => {
    setIsBookmarkVerificationFilter(() => newValue);
  }

  const isBookmarkInvestorsChangeHandler = async (newValue) => {
    setIsBookmarkInvestorsFilter(() => newValue);
  }


  const getSearchParams = () => {
    const searchParams = {};
    if (sellerAccount.id) {
      searchParams['seller'] = sellerAccount.id;
    }
    if (divisionFilter.length > 0) {
      // get the last division id from the division filter
      searchParams['division'] = divisionFilter[divisionFilter.length - 1];
    }

    if (statusFilter !== "0") {
      searchParams['status'] = statusFilter;
    }
    if (isChanceFilter !== "0") {
      searchParams['is_chance'] = isChanceFilter;
    }
    if (isBookmarkPremiumFilter !== "0") {
      searchParams['bookmark_premium'] = isBookmarkPremiumFilter;
    }

    if (isBookmarkChanceFilter !== "0") {
      searchParams['bookmark_chance'] = isBookmarkChanceFilter;
    }
    if (isBookmarkVerificationFilter !== "0") {
      searchParams['bookmark_verification'] = isBookmarkVerificationFilter;
    }

    if (isBookmarkInvestorsFilter !== "0") {
      searchParams['bookmark_investors'] = isBookmarkInvestorsFilter;
    }
    return searchParams;
  }




  const changePageHandler = async (event) => {
    const newPage = parseInt(event.target.value);
    const searchParams = getSearchParams();
    const data = await getPlots({ ...searchParams, 'page': newPage });

    setCurrentPage(() => newPage);
    setResultsCount(() => data.count);
    setNextPage(() => data.next);
    setPrevPage(() => data.previous);
    setPlots(() => data.results);
  }

  const searchApplyHandler = async () => {
    setIsLoading(true);
    const searchParams = getSearchParams();
    const data = await getPlots(searchParams);
    setResultsCount(data.count);
    setCurrentPage(1);
    setNextPage(data.next);
    setPrevPage(data.previous);
    setPlots(data.results);

    setIsLoading(false);
  }

  return (
    <div>
      {isLoading && <OverlayComponent />}
      <NavbarComponent />
      <div className="flex w-full p-4 mt-4 component-preview items-center justify-between gap-2 font-sans">
        <div className="w-full" style={{ display: 'flex', flexDirection: 'column' }}>

          <div className="flex p-4 mt-4 component-preview items-center justify-between gap-2 font-sans">
            <div className="flex gap-2 items-center">
              <label className="label">
                <span className="label-text">Seller Phone Number</span>
              </label>

              <Input type="text" maxLength={10} value={sellerSearchFilter} onChange={sellerPhoneChangeHandler} />
              <Button onClick={sellerSearchHandler} disabled={!sellerSearchIsValid}>Search</Button>
            </div>

            <div className="flex m-auto">
              {!sellerAccount.id && (
                <label className="label text-sm text-red-500">Seller not available. Search with valid phone number.</label>
              )}
              {sellerAccount.id && (
                <div>
                  <label className="label text-md font-bold">Seller Information</label>
                  <label className="label text-sm">
                    {sellerAccount.name}, {sellerAccount.phone_number}
                  </label>
                </div>
              )}
            </div>

            <Button className="w-48 bg-blue-200" onClick={(e) => navigate(`/map`)} >View Map</Button>
            <Button className="w-48 bg-blue-200" onClick={(e) => navigate(`/private-map`)} >Private Map</Button>

          </div>

          <div className="flex flex-wrap w-full p-4 mt-4 font-sans">
            <div className="w-1/5 max-w-xs">
              <label className="label">
                <span className="text-sm"><b>State</b></span>
              </label>
              <SelectDropdownComponent labelName='All States' options={divisionOptions.states} currValue={divisionFilter[0]} onChangeHandler={(value) => divisionOnChangeHandler('state', 0, value)} />
            </div>
            {
              divisionHierarchy.map((division, index) => {
                return <div className="w-1/5 max-w-xs">
                  <label className="label">
                    <span className="text-sm"><b>{division}</b></span>
                  </label>
                  <SelectDropdownComponent labelName={`All ${division}`} options={divisionOptions[division]} currValue={divisionFilter[index + 1]} onChangeHandler={(value) => divisionOnChangeHandler(division, index + 1, value)} />
                </div>
              })
            }

            <div className="w-1/5 max-w-xs">
              <label className="label">
                <span className="text-sm"><b>Status</b></span>
              </label>
              <SelectDropdownComponent labelName='All' options={statusOptions} currValue={statusFilter} onChangeHandler={statusChangeHandler} />
            </div>
            <div className="w-1/5 max-w-xs">
              <label className="label">
                <span className="text-sm"><b>Exposure Type</b></span>
              </label>
              <SelectDropdownComponent labelName='All' options={exposureTypeOptions} currValue={exposureTypeFilter} onChangeHandler={exposureTypeChangeHandler} />
            </div>

          </div>

          {console.log(user.is_superuser)}
          {user.is_superuser && (<div className="flex flex-wrap w-full p-4 mt-4 font-sans">

            <div className="w-1/5 max-w-xs">
              <label className="label">
                <span className="text-sm"><b>Suitable for Premium</b></span>
              </label>
              <SelectDropdownComponent labelName='All' options={chanceOptions} currValue={isBookmarkPremiumFilter} onChangeHandler={isBookmarkPremiumChangeHandler} />
            </div>

            <div className="w-1/5 max-w-xs">
              <label className="label">
                <span className="text-sm"><b>Suitable for Chance</b></span>
              </label>
              <SelectDropdownComponent labelName='All' options={chanceOptions} currValue={isBookmarkChanceFilter} onChangeHandler={isBookmarkChanceChangeHandler} />
            </div>

            <div className="w-1/5 max-w-xs">
              <label className="label">
                <span className="text-sm"><b>Suitable for Verification</b></span>
              </label>
              <SelectDropdownComponent labelName='All' options={chanceOptions} currValue={isBookmarkVerificationFilter} onChangeHandler={isBookmarkVerificationChangeHandler} />
            </div>

            <div className="w-1/5 max-w-xs">
              <label className="label">
                <span className="text-sm"><b>Suitable for Investors</b></span>
              </label>
              <SelectDropdownComponent labelName='All' options={chanceOptions} currValue={isBookmarkInvestorsFilter} onChangeHandler={isBookmarkInvestorsChangeHandler} />
            </div>

          </div>)}
          <div className="flex w-full p-4 mt-4 gap-2 font-sans justify-center">
            <Button color="primary" onClick={searchApplyHandler} >Apply</Button>
          </div>

        </div>


      </div>



      <div className='m-3'>
        {resultsCount > 0 && (<label className="label py-3 text-sm">Showing {resultsCount} plots</label>)}
        <Table className="border-2 border-solid border-[#ffde59]">
          <Table.Head>
            <span>ID</span>
            <span>Seller Name</span>
            <span>Seller Type</span>
            <span>Survey Number</span>
            <span>State</span>
            <span>{divisionFilter[0] ? divisionHierarchy[0] : 'division 01'}</span>
            <span>{divisionFilter[0] ? divisionHierarchy[1] : 'division 02'}</span>
            <span>{divisionFilter[0] ? divisionHierarchy[2] : 'division 03'}</span>

            <span>Plot Status</span>
            <span>Exposure Type</span>
            <span>Plot Size</span>
            <span>Price/Sq.Yd</span>
            <span>Created By</span>
            <span>Updated On</span>
            <span>Suitable for</span>
          </Table.Head>

          <Table.Body>
            {plots && plots.map((plot) => (
              // <Table.Row key={`land_${land.id}`} onClick={(e) => (land.status === 'active' && !user.is_superuser) ? e.preventDefault() : navigate(`/lands/${land.id}`) }>
              <Table.Row key={`plot_${plot.id}`} onClick={(e) => navigate(`/plots/${plot.id}`)}  >
                <span>{plot.id}</span>
                <span>{plot.seller.name}</span>
                <span>{plot.seller_type}</span>
                <span>{plot.survey_number}</span>
                <span>{plot.division_info[0].name || ''}</span>
                <span>{plot.division_info[1]?.name || ''}</span>
                <span>{plot.division_info[2]?.name || ''}</span>
                <span>{plot.division_info[3]?.name || ''}</span>
                <span>{plot.status}</span>
                <span>{plot.exposure_type}</span>
                <span>{plot.total_plot_size ? displayPlotSize(plot.total_plot_size) : ''}</span>
                <span>{plot.price_per_square_yard ? displayPrice(plot.price_per_square_yard) : ''}</span>
                {/* <span>{plot.is_chance ? 'Yes' : 'No'}</span> */}
                <span>{plot.created_by.name}</span>
                <span>{(new Date(plot.updated_at)).toLocaleDateString()}</span>
                <span>{plot.bookmark}</span>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="flex justify-between py-3">
          <Button color="primary" value={prevPage} onClick={changePageHandler} disabled={!prevPage}>Previous</Button>
          <label className="label font-bold">Page: {currentPage} </label>
          <Button color="primary" value={nextPage} onClick={changePageHandler} disabled={!nextPage}>Next</Button>
        </div>

      </div>

    </div>
  )
}