import { apiGetService } from './ApiService';

export const getStates = async () => {
  return await apiGetService('/location/divisions/?division_type=state');
}

export function getSlugFromName(name) {
  // convert a name to a slug
  return name.toLowerCase().replace(/ /g, '-');
}

export const getDivisionHierarchy = async (params) => {
  return await apiGetService(`/location/divisions/state_config/`, params);
}

export const getDivisions = async (params) => {
  return await apiGetService('/location/divisions/', params);
}


export const getAllHighways = async (params) => {
  return await apiGetService('/tags/', { tag_type: 'highway' });
}

export const getAllTags = async (params) => {
  return await apiGetService('/tags/', params);
}
