import NavbarComponent from "../components/NavbarComponent";
import { getStates, getDivisions, getAllHighways, getAllTags, getDivisionHierarchy, getSlugFromName } from "../services/LocationsService";
import { useState, useEffect } from "react";
import SelectDropdownComponent from "../components/SelectDropdownComponent";
import { Button, Checkbox, Input, InputGroup, Textarea, } from "react-daisyui";
import { isPhoneNumber } from "../utils/validators";
import { getSeller } from "../services/UserService";
import { createLand, getLandData, updateLand } from "../services/LandsService";
import { useParams } from "react-router-dom";
import OverlayComponent from "../components/OverlayComponent";
import NotificationComponent from "../components/NotificationComponent";
import ImageComponent from "../components/ImageComponent";

export default function LandPage() {
  const params = useParams();
  const [addPage, setAddPage] = useState(false); // stores if page is being added or are we editing existing page.
  useEffect(() => {
    (async () => {
      await fetchStates();
      await fetchAllHighways();
      await fetchAllTags();
      if (params.land_id) {
        setAddPage(false);
        await fetchLandData(params.land_id);
      } else {
        setAddPage(true);
      }
    })();
  }, [params]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });

  const [landId, setLandId] = useState('');
  const [sellerSearchFilter, setSellerSearchFilter] = useState('');
  const [warningMessageFilter, setWarningMessageFilter] = useState('')
  const [sellerSearchIsValid, setSellerSearchIsValid] = useState(false);
  const [sellerSearchInfo, setSellerSearchInfo] = useState({ 'id': '', 'name': '', 'phone_number': '' })
  const [sellerType, setSellerType] = useState("agent");
  const [landTeams, setLandTeams] = useState([])
  const [divisionHierarchy, setDivisionHierarchy] = useState([]) // ['district", "mandal", ....]
  const [divisionFilter, setDivisionFilter] = useState([]); // [id,id,id]
  const [landSizeAcresFilter, setLandSizeAcresFilter] = useState('');
  const [landSizeGuntasFilter, setLandSizeGuntasFilter] = useState('');
  const [landSizeCentsFilter, setLandSizeCentsFilter] = useState('');
  const [landPriceFilter, setLandPriceFilter] = useState('');

  const [approachRoadFilter, setApproachRoadFilter] = useState(false);
  const [fencingRoadFilter, setFencingRoadFilter] = useState(false);

  const [waterSourceFilter, setWaterSourceFilter] = useState(false)
  const [wellFilter, setWellFilter] = useState(false);
  const [canalFilter, setCanalFilter] = useState(false);
  const [dripFilter, setDripFilter] = useState(false)
  const [sprinklerFilter, setSprinklerFilter] = useState(false)
  const [boreWellFilter, setBoreWellFilter] = useState(false)
  const [streamFilter, setStreamFilter] = useState(false)

  const [allHighways, setAllHighways] = useState([]);
  const [landHighways, setLandHighways] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [landNonHighwayTags, setLandNonHighwayTags] = useState([]);

  const [landBookmark, setLandBookmark] = useState(false);
  const [oneAcrePremium, setOneAcrePremium] = useState(false)
  const [chance, setChance] = useState(false)
  const [siteVerification, setSiteVerification] = useState(false)
  const [investors, setInvestors] = useState(false)

  const [electricityFilter, setElectricityFilter] = useState(false);

  const [roadType, setRoadType] = useState("");
  const [cropType, setCropType] = useState('');
  const [soilType, setSoilType] = useState('');
  const [locationLink, setLocationLink] = useState('');
  const [approximateLocationLink, setApproximateLocationLink] = useState('');
  const [roadWidth, setRoadWidth] = useState(0);
  const [surveyNumber, setSurveyNumber] = useState('');
  const [lpNumber, setLpNumber] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [orrDistance, setOrrDistance] = useState('');
  const [fencingDescription, setFencingDescription] = useState('');
  const [isPhsyicallyVerifiedFilter, setIsPhsyicallyVerifiedFilter] = useState(false);
  const [isChancePropertyFilter, setIsChancePropertyFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState("pending");
  const [exposureTypeFilter, setExposureTypeFilter] = useState('public');

  const [landImages, setLandImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [approachRoadImages, setApproachRoadImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [fencingRoadImages, setFencingRoadImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [waterSourceImages, setWaterSourceImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [electricityImages, setElectricityImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [surveyImages, setSurveyImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [passbookImages, setPassbookImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [landExtractsImages, setLandExtractsImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })

  const [divisionOptions, setDivisionOptions] = useState({});
  const statusOptions = [{ 'id': 'pending', 'name': ' Pending' }, { 'id': 'active', 'name': 'Active' }, { 'id': 'sold', 'name': 'Sold' }, { 'id': 'expired', 'name': 'Expired' }];
  const exposureTypeOptions = [{ 'id': 'public', 'name': 'Public' }, { 'id': 'private', 'name': 'Private' }, { 'id': 'super_private', 'name': 'Super Private' }, { 'id': 'sweet_deal', 'name': 'Sweet Deal' }]
  const roadTypeOptions = [{ 'id': 'kacha', 'name': 'Kacha' }, { 'id': 'blacktop', 'name': 'Blacktop' }, { 'id': 'formation', 'name': 'Formation' }, { 'id': 'highway_road', 'name': 'Highway Road' }, { 'id': 'cc_road', 'name': 'CC Road' }]
  const sellerTypeOptions = [{ 'id': 'agent', 'name': 'Agent' }, { 'id': 'owner', 'name': 'Owner' }, { 'id': 'facilitated', 'name': 'Facilitated' }]

  let stateSpecifics = {
    metricOptions: [],
    hasDistanceFromOrr: false,
    hasLPNumber: false,
    landExtractsTitle: "Land Extracts"
  };

  const andhraPradeshSpecifics = {
    ...stateSpecifics,
    metricOptions: [{ id: 'acres', name: 'Acres', limit: 0, landSizeState: landSizeAcresFilter, setlandSizeState: setLandSizeAcresFilter }, { id: 'cents', name: 'Cents', limit: 100, landSizeState: landSizeCentsFilter, setlandSizeState: setLandSizeCentsFilter }],
    hasLPNumber: true,
    landExtractsTitle: "Meebhoomi adangal extacts"
  }
  const telanganaSpecifics = {
    ...stateSpecifics,
    metricOptions: [{ id: 'acres', name: 'Acres', landSizeState: landSizeAcresFilter, limit: 0, setlandSizeState: setLandSizeAcresFilter }, { id: 'guntas', name: 'Guntas', limit: 40, landSizeState: landSizeGuntasFilter, setlandSizeState: setLandSizeGuntasFilter }],
    hasDistanceFromOrr: true,
    landExtractsTitle: "Dharani extracts"
  }

  if (divisionFilter[0] === 24) {
    stateSpecifics = telanganaSpecifics;
  }

  if (divisionFilter[0] === 1) {
    stateSpecifics = andhraPradeshSpecifics;
  }


  const fetchLandData = async (land_id) => {
    setIsLoading(true);
    try {
      const data = await getLandData(land_id);

      setLandId(data.id);

      const stateSlug = getSlugFromName(data.division_info[0].name);

      const hierarchy = await fetchDivisionHierarchy(stateSlug);

      hierarchy.forEach((division, index) => {
        // get the division options for the division
        const divisionId = data.division_info[index].id;
        (async () => {
          await fetchDivisionOptions(divisionId, division);
        })();
      })

      setDivisionFilter(data.division_info.map((division) => {
        return division.id
      }));


      // setSellerAccount({ 'id': data.seller.id, 'name': data.seller.name, 'phone_number': data.seller.phone_number })
      // setSellerType(data.seller_type);
      setLandTeams(data.land_teams.map(team => ({ 'account': team.account, 'membership_type': team.membership_type, 'is_primary': team.is_primary })))
      setLandSizeAcresFilter(data.total_land_size_in_acres.acres);
      setLandSizeGuntasFilter(data.total_land_size_in_acres.guntas || '');
      setLandSizeCentsFilter(data.total_land_size_in_acres.cents || '');
      setLandPriceFilter(data.price_per_acre);
      setAdditionalInformation(data.description);
      setOrrDistance(data.distance_from_orr);
      setWarningMessageFilter(data.disclaimer)
      setIsChancePropertyFilter(data.is_chance);
      setStatusFilter(data.status || 'pending');
      setExposureTypeFilter(data.exposure_type || 'public');
      setIsPhsyicallyVerifiedFilter(data.is_physically_verified);
      setRoadWidth(data.approach_road_length)
      setRoadType(data.approach_road_type)
      setApproachRoadFilter(data.approach_road);
      setFencingRoadFilter(data.fencing);
      setWaterSourceFilter(data.water_source || false);

      setElectricityFilter(data.electricity);
      setCropType(data.crop_type || '');
      setSoilType(data.soil_type || '');
      setLocationLink(data.location_link || '');
      setApproximateLocationLink(data.approximate_location_link || '');
      setSurveyNumber(data.survey_number || '');
      setLpNumber(data.lp_number || '');
      setFencingDescription(data.fencing_description)
      setLandImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'land') || [], 'removedImageLinks': [] });
      setWellFilter(data.water_source_data?.well)
      setCanalFilter(data.water_source_data?.canal)
      setDripFilter(data.water_source_data?.drip)
      setSprinklerFilter(data.water_source_data?.sprinkler)
      setStreamFilter(data.water_source_data?.stream)
      setBoreWellFilter(data.water_source_data?.bore_well)
      // setTags(data.tags.map(tag => tag.name));
      const allTmpLandHighways = [];
      const allTmpNonLandHighwaysTags = [];

      if (data.tags.length) {
        data.tags.forEach(tag => {
          if (tag.tag_type === 'highway') {
            allTmpLandHighways.push(tag.slug);
          } else {
            allTmpNonLandHighwaysTags.push(tag);
          }

        });
      }
      setLandHighways(allTmpLandHighways);
      setLandNonHighwayTags(allTmpNonLandHighwaysTags);
      setOneAcrePremium(data.land_bookmark_data?.one_acre_premium)
      setChance(data.land_bookmark_data?.chance)
      setSiteVerification(data.land_bookmark_data?.site_verification)
      setInvestors(data.land_bookmark_data?.investors || false)

      console.log("data received", data)

      setApproachRoadImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'approach_road') || [], 'removedImageLinks': [] });
      setFencingRoadImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'fencing') || [], 'removedImageLinks': [] });
      setWaterSourceImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'water_source') || [], 'removedImageLinks': [] });
      setElectricityImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'power_source') || [], 'removedImageLinks': [] });
      setSurveyImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'survey') || [], 'removedImageLinks': [] });
      setPassbookImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'passbook') || [], 'removedImageLinks': [] });
      setLandExtractsImages({ 'imageFiles': [], 'imageLinks': data.land_media.filter(item => item.category === 'land_extracts') || [], 'removedImageLinks': [] });
    } catch (error) {
      triggerNotification(`Unable to fetch land with id: ${land_id}`, 'error');
    }
    setIsLoading(false);
  }

  // fetches all states that are available and updates the stateOptions with the results
  const fetchStates = async () => {
    const data = await getStates();
    const options = data.results.map((stateObj) => ({ 'id': stateObj.id, 'name': stateObj.name, 'slug': stateObj.slug }));

    setDivisionOptions({ 'states': options });
  }

  const fetchDivisionHierarchy = async (stateSlug) => {
    const data = await getDivisionHierarchy({
      'state_slug': stateSlug
    });
    setDivisionHierarchy(data)
    return data;
  }

  const fetchDivisionOptions = async (parentDivisionId, divisionType) => {
    // fetch divisions based on divisionType and parentDivisionId
    const data = await getDivisions({
      'parent_id': parentDivisionId,
      'division_type': divisionType
    });

    const nextDivision = divisionType;
    const index = divisionHierarchy.indexOf(divisionType);
    setDivisionOptions(prev => {
      const newOptions = {
        ...prev,
        [nextDivision]: data.results || [],
        // Clear all subsequent division options
        ...divisionHierarchy.slice(index + 1).reduce((acc, div) => ({ ...acc, [div]: [] }), {})
      }

      return newOptions;
    });
    // return data;
  }

  const fetchAllHighways = async () => {
    const data = await getAllHighways();
    if (data.count) {
      setAllHighways(data.results);
    }
  }

  const fetchAllTags = async () => {
    const data = await getAllTags();
    if (data.count) {
      setAllTags(data.results);
    }
  }

  const divisionOnChangeHandler = async (division, index, newValue) => {
    const castedNewValue = parseInt(newValue);
    console.log(newValue, castedNewValue)
    if (castedNewValue === 0) {
      setDivisionHierarchy([])
      setDivisionFilter([])
      // clear all the division options except states 
      setDivisionOptions({ 'states': divisionOptions.states })
    } else {
      setDivisionFilter((prev) => {
        const newFilter = [...prev.slice(0, index), castedNewValue];
        // Clear subsequent divisions when a higher-level division changes
        return newFilter;
      });

      // Fetch new options for the next division level
      if (index === 0) {
        // If state changed, fetch new division hierarchy and district options
        const stateSlug = divisionOptions.states.find(state => state.id === castedNewValue)?.slug;
        if (stateSlug) {
          const newHierarchy = await fetchDivisionHierarchy(stateSlug);
          setDivisionHierarchy(newHierarchy);

          if (newHierarchy.length > 0) {
            fetchDivisionOptions(castedNewValue, newHierarchy[0]);
          }
        }
      } else {
        // For other levels, fetch options for the next level
        const nextDivision = divisionHierarchy[index];
        console.log("nextDivision", nextDivision)
        if (nextDivision) {
          await fetchDivisionOptions(castedNewValue, nextDivision);
        }
      }
    }
  };

  const sellerPhoneChangeHandler = async (event) => {
    setSellerSearchFilter(event.target.value);
  }

  useEffect(() => {
    if (isPhoneNumber(sellerSearchFilter)) {
      setSellerSearchIsValid(true);
    } else {
      setSellerSearchIsValid(false);
    }
  }, [sellerSearchFilter]);

  const warningMessageChangeHandler = async (event) => {
    setWarningMessageFilter(event.target.value);
  }





  const sellerSearchHandler = async (event) => {
    try {
      const data = await getSeller({ 'phone_number': '91' + sellerSearchFilter })
      if (data) {
        setSellerSearchInfo({ 'id': data.id, 'name': data.name, 'phone_number': data.phone_number });
      } else {
        setSellerSearchInfo({ 'id': '', 'name': '', 'phone_number': '' })
      }
    } catch (error) {
      setSellerSearchInfo({ 'id': '', 'name': '', 'phone_number': '' })
    }
  };

  const sellerTypeChangeHandler = async (newValue) => {
    setSellerType(newValue);
  }
  const addSellerHandler = async () => {
    if (sellerSearchInfo.id) {
      setLandTeams((prevLandTeams) => {
        const tmpLandTeams = prevLandTeams.length > 0 ? [...prevLandTeams] : [];
        let newLandTeams = [];
        if (tmpLandTeams.length > 0) {
          newLandTeams = tmpLandTeams.filter(eachTeam => {
            if (eachTeam.account !== sellerSearchInfo.id && eachTeam.membership_type !== sellerType) {
              return true;
            } else {
              return false;
            }
          })
        }
        newLandTeams.push({
          'account': {
            'name': sellerSearchInfo.name, 'phone_number': sellerSearchInfo.phone_number,
            'id': sellerSearchInfo.id
          }, 'membership_type': sellerType, 'is_primary': false,
        });
        return newLandTeams;
      })
    }
  }
  const isPrimaryChangeHandler = async (membershipType) => {
    setLandTeams((prevLandTeams) => {
      return prevLandTeams.map((landTeam) => {
        if (landTeam.membership_type === membershipType) {
          landTeam.is_primary = true;
        }
        else {
          landTeam.is_primary = false;
        }
        return landTeam;
      })
    })
  }

  useEffect(() => {
    console.log("landTeams", landTeams)
  }, [landTeams])

  const statusChangeHandler = async (newValue) => {
    setStatusFilter(newValue);
  }
  const exposureTypeChangeHandler = async (newValue) => {
    setExposureTypeFilter(newValue)
  }

  const roadTypeChangeHandler = async (newValue) => {

    setRoadType(newValue);

  }

  const cropChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setCropType(value);
    } else {
      setCropType('');
    }
  }

  const soilChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setSoilType(value);
    } else {
      setSoilType('');
    }
  }

  const handleWheel = async (event) => {
    event.target.blur();
    // event.preventDefault();
    // event.stopPropagation();
  }

  const landPriceChangeHandler = async (event) => {
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setLandPriceFilter(event.target.value);
    }
  }

  const approachRoadChangeHandler = async () => {
    setApproachRoadFilter(() => !approachRoadFilter);
    if (approachRoadFilter) {
      setRoadWidth(0)
      setRoadType('')
    }

  }

  const fencingRoadChangeHandler = async () => {
    setFencingRoadFilter(() => !fencingRoadFilter);
  }

  const waterSourceChangeHandler = async () => {
    setWaterSourceFilter(() => !waterSourceFilter);
  }

  const wellChangeHandler = async () => {
    setWellFilter(() => !wellFilter);
  }

  const canalChangeHandler = async () => {
    setCanalFilter(() => !canalFilter);
  }

  const dripChangeHandler = async () => {
    setDripFilter(() => !dripFilter);
  }


  const sprinklerChangeHandler = async () => {
    setSprinklerFilter(() => !sprinklerFilter);
  }

  const streamChangeHandler = async () => {
    setStreamFilter(() => !streamFilter);
  }

  const boreWellChangeHandler = async () => {
    setBoreWellFilter(() => !boreWellFilter);
  }

  const anyHighwayChangeHandler = async (event, highway) => {
    setLandHighways((prevLandHighways) => {
      const tmpLandHighways = [...prevLandHighways];

      if (event.target.value === 'false') {
        tmpLandHighways.push(highway.slug);
      } else {
        const index = tmpLandHighways.indexOf(highway.slug);
        if (index > -1) {
          tmpLandHighways.splice(index, 1);
        }
      }

      return tmpLandHighways;
    });
  }

  const landBookmarkChangeHandler = async () => {
    setLandBookmark(() => !landBookmark);
  }

  const oneAcrePremiumChangeHandler = async () => {
    setOneAcrePremium(() => !oneAcrePremium);
  }

  const chanceChangeHandler = async () => {
    setChance(() => !chance);
  }

  const siteVerificationChangeHandler = async () => {
    setSiteVerification(() => !siteVerification);
  }


  const investorsChangeHandler = async () => {
    setInvestors(() => !investors);
  }


  const electricityChangeHandler = async () => {
    setElectricityFilter(() => !electricityFilter);
  }

  const locationLinkChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setLocationLink(value);
    } else {
      setLocationLink('');
    }
  }

  const approximateLocationLinkChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setApproximateLocationLink(value);
    } else {
      setApproximateLocationLink('');
    }
  }





  const roadWidthChangeHandler = async (event) => {
    if (/^\d*$/.test(event.target.value)) {
      setRoadWidth(event.target.value);
    }
  }
  const surveyNumberChangeHandler = async (event) => {
    setSurveyNumber(event.target.value);

  }
  const lpNumberChangeHandler = async (event) => {
    setLpNumber(event.target.value);
  }

  const additionalInformationChangeHandler = async (event) => {
    setAdditionalInformation(event.target.value);
  }

  const orrDistanceChangeHandler = async (event) => {
    setOrrDistance(event.target.value);
  }

  const fencingDescriptionChangeHandler = async (event) => {
    setFencingDescription(event.target.value);

  }

  const isPhsyicallyVerifiedChangeHandler = async () => {
    setIsPhsyicallyVerifiedFilter(() => !isPhsyicallyVerifiedFilter);
  }

  const isChancePropertyChangeHandler = async () => {
    setIsChancePropertyFilter(() => !isChancePropertyFilter);
  }

  const getUpdatedLandData = () => {
    const landParams = {};
    landParams['land_teams'] = [
      ...landTeams.map(team => ({ 'name': team.name, 'account': team.account.id, 'membership_type': team.membership_type, 'is_primary': team.is_primary }))
    ]

    if (divisionFilter.length > divisionHierarchy.length) {
      landParams['division'] = divisionFilter
    }


    landParams["crop_type"] = cropType !== '' ? cropType : null;
    landParams["soil_type"] = soilType !== '' ? soilType : null;


    landParams['disclaimer'] = warningMessageFilter !== '' ? warningMessageFilter : null;

    if (statusFilter !== "0") {
      landParams["status"] = statusFilter
    }
    landParams["exposure_type"] = exposureTypeFilter


    landParams["total_land_size_in_acres"] = {};
    stateSpecifics.metricOptions.forEach(option => {
      landParams["total_land_size_in_acres"][option.id] = parseFloat(option.landSizeState) || 0;
    });


    landParams['price_acre'] = { 'price': parseFloat(landPriceFilter), 'price_type': 'lakh' }
    landParams['approach_road'] = approachRoadFilter;
    landParams['approach_road_length'] = roadWidth;
    landParams['approach_road_type'] = roadType;
    landParams['fencing'] = fencingRoadFilter;

    landParams['water_source'] = waterSourceFilter;
    landParams['electricity'] = electricityFilter;
    landParams['location_link'] = locationLink;
    landParams['approximate_location_link'] = approximateLocationLink;
    landParams['survey_number'] = surveyNumber;
    landParams['lp_number'] = stateSpecifics.hasLPNumber ? lpNumber : null;
    landParams['description'] = additionalInformation;
    landParams['distance_from_orr'] = orrDistance && stateSpecifics.hasDistanceFromOrr ? orrDistance : null;
    landParams['is_physically_verified'] = isPhsyicallyVerifiedFilter;
    landParams['is_chance'] = isChancePropertyFilter;
    landParams['fencing_description'] = fencingDescription;
    landParams['land_media'] = []
    landParams['removed_media_files'] = []
    landParams['land_bookmark_data'] = { 'one_acre_premium': oneAcrePremium, 'chance': chance, 'site_verification': siteVerification, 'investors': investors }
    landParams['water_source_data'] = {
      'well': wellFilter,
      'canal': canalFilter,
      'drip': dripFilter,
      'sprinkler': sprinklerFilter,
      'bore_well': boreWellFilter,
      'stream': streamFilter
    }

    let requiredTags = [];

    if (!addPage) {
      allTags.forEach(eachTag => {
        if (eachTag.tag_type != 'highway') {

        } else {
          if (landHighways.includes(eachTag.slug) && divisionFilter[0] === 24) {
            requiredTags.push(eachTag);
          }
        }
      });
      requiredTags = [...landNonHighwayTags, ...requiredTags];
    } else {
      allTags.forEach(eachTag => {
        if (landHighways.includes(eachTag.slug) && divisionFilter[0] === 24) {
          requiredTags.push(eachTag);
        }
      });
    }

    landParams['tags'] = requiredTags;

    if (landImages.imageFiles.length > 0) {
      for (const imageFileObj of landImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'land', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (landImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(landImages.removedImageLinks);
    };

    if (approachRoadImages.imageFiles.length > 0) {
      for (const imageFileObj of approachRoadImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'approach_road', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }

    }
    if (approachRoadImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(approachRoadImages.removedImageLinks);
    };

    if (fencingRoadImages.imageFiles.length > 0) {
      for (const imageFileObj of fencingRoadImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'fencing', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (fencingRoadImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(fencingRoadImages.removedImageLinks);
    }


    if (waterSourceImages.imageFiles.length > 0) {
      for (const imageFileObj of waterSourceImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'water_source', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (waterSourceImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(waterSourceImages.removedImageLinks);
    }


    if (electricityImages.imageFiles.length > 0) {
      for (const imageFileObj of electricityImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'power_source', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (electricityImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(electricityImages.removedImageLinks);
    }


    if (surveyImages.imageFiles.length > 0) {
      for (const imageFileObj of surveyImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'survey', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (surveyImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(surveyImages.removedImageLinks);
    }

    if (passbookImages.imageFiles.length > 0) {
      for (const imageFileObj of passbookImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'passbook', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (passbookImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(passbookImages.removedImageLinks);
    }

    if (landExtractsImages.imageFiles.length > 0) {
      for (const imageFileObj of landExtractsImages.imageFiles) {
        landParams['land_media'].push({ 'media_type': 'image', 'category': 'land_extracts', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (landExtractsImages.removedImageLinks.length > 0) {
      landParams['removed_media_files'] = landParams['removed_media_files'].concat(landExtractsImages.removedImageLinks);
    }
    return landParams;

  }

  const landSubmitHandler = async () => {

    if (landTeams.length === 0 || divisionFilter.length !== divisionHierarchy.length + 1) {
      setErrorMessage('Mandatory fields are missing. Please check Seller, District, Mandal, Village');
    } else {
      setErrorMessage('');
      const landParams = getUpdatedLandData();
      console.log(landParams)
      setIsLoading(true);
      try {
        const data = addPage ? await createLand(landParams) : await updateLand(landId, landParams);
        if (addPage) {
          window.history.replaceState(null, '', `/lands/${data.id}`);
          setLandId(data.id);
          setAddPage(false);

          triggerNotification(`Land created with id: ${data.id}`, 'success. Please wait for refresh');
        } else {
          triggerNotification('Land updated. Please wait for refresh', 'success');
        }
        setTimeout(() => {
          window.location.reload()
        }, 3000
        )

      } catch (error) {
        if (error.response.status === 400) {
          setErrorMessage(() => 'Mandatory fields are missing');
        }
        else if (error.response.status === 401) {
          setErrorMessage(() => "You can't cheat me. Please login.");
        } else {
          setErrorMessage(() => "Unknown error. Please contact support.");
        }
        triggerNotification('Operation failed.', 'error');
      }
      setIsLoading(false);
    }
  }

  const triggerNotification = (message, type) => {
    setNotification({ show: true, message: message, type: type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: '' });
    }, 3000);
  };

  return (
    <div>
      {isLoading && <OverlayComponent />}
      <NavbarComponent />
      <div className="m-5">
        <div className="w-4/5 m-auto">
          <div className="flex w-full items-center justify-center relative">
            <label className="label text-2xl text-bold text-center">
              {addPage ? 'Add Land' : 'Update Land'}
            </label>
            {/* <Button className="w-48 bg-blue-200 absolute right-map" onClick={(e) => navigate(`/map`)}>
              View Map
            </Button>
            <Button className="w-48 bg-blue-200 absolute right-0" onClick={(e) => navigate(`/private-map`)}>
              Private Map
            </Button> */}
          </div>
          <div className="flex flex-col w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="flex items-center gap-4 w-full justify-between">
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <label className="label">
                    <span className="label-text">Seller Phone Number</span>
                  </label>
                </div>
                <div className="flex gap-2">
                  <Input type="text" maxLength={10} value={sellerSearchFilter} onChange={sellerPhoneChangeHandler} />
                  <Button onClick={sellerSearchHandler} disabled={!sellerSearchIsValid}>Search</Button>
                </div>
              </div>
              <div className="flex m-auto">
                {!sellerSearchInfo.id && (
                  <label className="label text-sm text-red-500">Seller not available. Search with valid phone number.</label>
                )}
                {sellerSearchInfo.id && (
                  <div>
                    <label className="label text-md font-bold">Seller Information</label>
                    <label className="label text-sm">
                      {sellerSearchInfo.name}, {sellerSearchInfo.phone_number}
                    </label>
                  </div>
                )}
                {/* { !addPage && (<label className="label text-sm">
                                  {sellerAccount.name},{sellerAccount.phone_number}
                                      </label>)} */}
              </div>
              <div className="flex gap-4 ml-2 mr-6">
                <SelectDropdownComponent labelName={'Seller Type'} options={sellerTypeOptions} currValue={sellerType} onChangeHandler={sellerTypeChangeHandler} />
              </div>
              {
                sellerSearchInfo.id && sellerType &&
                <Button color="primary" className="w-fit" onClick={addSellerHandler}>Add</Button>
              }
            </div>
            {landTeams.length > 0 && <div className="flex flex-row form-control gap-2 w-full m-auto">
              {landTeams.map((team) => {
                return (
                  <label key={team.account} className="label px-2 shadow-md border rounded-md">
                    <div className="relative">
                      <p className="label text-md font-bold capitalize">{team.membership_type}</p>
                      <p>{team.account.name}, {team.account.phone_number}</p>
                      <input type="radio" name="isPrimary" onChange={() => isPrimaryChangeHandler(team.membership_type)} className="radio absolute top-0 right-0 checked:bg-blue-500" checked={team.is_primary} />
                    </div>
                  </label>
                )
              })
              }
            </div>}
          </div>

          <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">
            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">States<span className="text-red-600">*</span></span>
                </label>
                <SelectDropdownComponent labelName='All States' options={divisionOptions['states']} currValue={divisionFilter[0]} onChangeHandler={(value) => divisionOnChangeHandler('state', 0, value)} />
              </div>
              {
                divisionHierarchy.length > 0 && (
                  divisionHierarchy.map((division, index) => {
                    return (
                      <div className="form-control w-full max-w-xs">
                        <label className="label">
                          <span className="label-text">{division}</span>
                        </label>
                        <SelectDropdownComponent labelName={`All ${division}`} options={divisionOptions[division]} currValue={divisionFilter[index + 1]} onChangeHandler={(value) => divisionOnChangeHandler(division, index + 1, value)} />
                      </div>
                    )
                  })
                )
              }
            </div>

            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">

              {stateSpecifics.metricOptions.map((metricOption, index) => {
                return <div className="form-control w-full max-w-xs" key={index}>
                  <label className="label">
                    <span className="label-text">Land Size({metricOption.name})</span>
                  </label>
                  <InputGroup>
                    <Input type="text" value={metricOption.landSizeState} onChange={e => { if ((e.target.value < metricOption.limit || metricOption.limit === 0) && /^\d*$/.test(e.target.value)) { metricOption.setlandSizeState(e.target.value) } }} placeholder="0" bordered />
                    <span className="text-sm">{metricOption.name}</span>
                  </InputGroup>
                </div>
              })}

              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Price per Acre(in Lakhs)</span>
                </label>
                <InputGroup>
                  <Input type="text" value={landPriceFilter} onChange={landPriceChangeHandler} placeholder="0" bordered />
                  <span className="text-sm">Lakhs</span>
                </InputGroup>
              </div>
            </div>
          </div>



          <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">
            <div className=" flex p-4 mt-4 items-center gap-2 font-sans">
              <div className="flex-none text-sm">
                <div className="flex items-center">
                  <Checkbox color="primary" value={approachRoadFilter} onChange={approachRoadChangeHandler} checked={approachRoadFilter} />
                  <label className="ml-2 text-sm">Approach Road</label>
                </div>
              </div>

              <div className="max-w-xs">
                <label className="label">
                  <span className="label-text">Road Width</span>
                </label>
                <InputGroup>
                  <Input type="text" onChange={roadWidthChangeHandler} value={roadWidth} bordered />
                  <span className="text-sm">Ft</span>
                </InputGroup>
              </div>

              <div className="max-w-xs">
                <label className="label">
                  <span className="label-text">Road Type</span>
                </label>
                <SelectDropdownComponent labelName={'Road Type'} options={roadTypeOptions} currValue={roadType} onChangeHandler={roadTypeChangeHandler} />
              </div>
              <ImageComponent multipleImages={false} defaultValues={approachRoadImages} onChangeHandler={setApproachRoadImages} />
            </div>


            <div className="flex  p-4 items-center gap-2 font-sans">
              <div className="flex flex-none text-sm">
                <label className="label"><b>Water Source</b></label>
              </div>

              <div className="flex gap-4 ml-2 mr-6">
                <div className="flex items-center">
                  <Checkbox color="primary" value={wellFilter} onChange={wellChangeHandler} checked={wellFilter} />
                  <label className="ml-2 text-sm min-w-full">Well</label>
                </div>
                <div className="flex items-center">
                  <Checkbox color="primary" value={canalFilter} onChange={canalChangeHandler} checked={canalFilter} />
                  <label className="ml-2 text-sm min-w-full">Canal</label>
                </div>
                <div className="flex items-center">
                  <Checkbox color="primary" value={dripFilter} onChange={dripChangeHandler} checked={dripFilter} />
                  <label className="ml-2 text-sm min-w-full">Drip</label>
                </div>
                <div className="flex items-center">
                  <Checkbox color="primary" value={sprinklerFilter} onChange={sprinklerChangeHandler} checked={sprinklerFilter} />
                  <label className="ml-2 text-sm min-w-full">Sprinkler</label>
                </div>
                <div className="flex items-center">
                  <Checkbox color="primary" value={streamFilter} onChange={streamChangeHandler} checked={streamFilter} />
                  <label className="ml-2 text-sm min-w-full">Stream</label>
                </div>
                <div className="flex items-center">
                  <Checkbox color="primary" value={boreWellFilter} onChange={boreWellChangeHandler} checked={boreWellFilter} />
                  <label className="ml-2 text-sm min-w-full">Bore Well</label>
                </div>
              </div>
              <ImageComponent multipleImages={false} defaultValues={waterSourceImages} onChangeHandler={setWaterSourceImages} />
            </div>



            <div className="flex w-full p-4 mt-4  items-center gap-4 font-sans">
              <div className=" flex items-center">
                <Checkbox color="primary" value={fencingRoadFilter} onChange={fencingRoadChangeHandler} checked={fencingRoadFilter} />
                <label className="ml-2 text-sm">Fencing</label>
              </div>
              <div className="w-full max-w-md">
                <label className="label">
                  <span className="label-text">Fencing Description</span>
                </label>
                <Textarea className="w-full" type="text" onChange={fencingDescriptionChangeHandler} value={fencingDescription} />
              </div>
              <ImageComponent multipleImages={false} defaultValues={fencingRoadImages} onChangeHandler={setFencingRoadImages} />
            </div>




            <div className="flex p-4 w-full items-center gap-2 font-sans">

              <div className="flex max-w-xs">
                <div className="flex items-center">
                  <Checkbox color="primary" value={electricityFilter} onChange={electricityChangeHandler} checked={electricityFilter} />
                  <label className="ml-2 text-sm">Electricity</label>
                </div>
              </div>
              <ImageComponent multipleImages={false} defaultValues={electricityImages} onChangeHandler={setElectricityImages} />
            </div>


            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Crop Type</span>
                </label>
                <Input type="text" onChange={cropChangeHandler} value={cropType} />
                {/* <SelectDropdownComponent labelName={'Crop Type'} options={cropOptions} currValue={cropFilter} onChangeHandler={cropChangeHandler} /> */}
              </div>

              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Soil Type</span>
                </label>
                <Input type="text" onChange={soilChangeHandler} value={soilType} />
                {/* <SelectDropdownComponent labelName={'Soil Type'} options={soilOptions} currValue={soilFilter} onChangeHandler={soilChangeHandler} /> */}
              </div>



            </div>
          </div>

          <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">


            {stateSpecifics.hasDistanceFromOrr &&
              <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
                <div className="form-control w-full max-w-xs">
                  <label className="label">
                    <span className="label-text">Distance From ORR (In km)</span>
                  </label>
                  <Input type="number" min="0"
                    pattern="[0-9]+([.][0-9]+)?"
                    onWheel={
                      handleWheel
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        const value = parseFloat(e.target.value);
                        if (isNaN(value) || value < 0) {
                          setOrrDistance(value || '');
                        } else {
                          setOrrDistance(value);
                        }
                      } else {
                        setOrrDistance('')
                      }
                    }}
                    value={orrDistance} />
                </div>
              </div>}

            {divisionFilter[0] === 24 && <div className="flex flex-wrap p-4 mt-4  items-center gap-2 font-sans">
              <div className="flex flex-none text-sm">
                <label className="label"><b>National Highway</b></label>
              </div>
              {allHighways.map((highway, index) => (
                <div className="flex items-center">
                  <Checkbox key={highway.slug} color="primary" value={landHighways.includes(highway.slug)} onChange={e => anyHighwayChangeHandler(e, highway)} checked={landHighways.includes(highway.slug)} />
                  <label className="ml-2 text-sm min-w-full"><span className="capitalize-name">{highway.name}</span></label>
                </div>
              ))}
            </div>}

            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-4/5">
                <label className="label">
                  <span className="label-text">Exact Location Link</span>
                </label>
                <Input type="text" onChange={locationLinkChangeHandler} value={locationLink} />
              </div>
              <div className="form-control w-4/5">
                <label className="label">
                  <span className="label-text">Approximate Location Link</span>
                </label>
                <Input type="text" onChange={approximateLocationLinkChangeHandler} value={approximateLocationLink} />
              </div>

            </div>

            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-4/5">
                <label className="label">
                  <span className="label-text">Additional Information</span>
                </label>
                <Textarea type="text" onChange={additionalInformationChangeHandler} value={additionalInformation} />
              </div>
            </div>
            <label className="ml-2"><b>Upload Land Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setLandImages} defaultValues={landImages} />
          </div>

          <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">
            <div className="flex w-full items-center justify-center">
              <label className="label text-2xl">
                <b> Verification Data</b>
              </label>
            </div>
            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Survey Number</span>
                </label>
                <Input type="text" onChange={surveyNumberChangeHandler} value={surveyNumber} />
              </div>
              {
                stateSpecifics.hasLPNumber &&
                <div className="form-control w-full max-w-xs">
                  <label className="label">
                    <span className="label-text">LP Number</span>
                  </label>
                  <Input type="text" onChange={lpNumberChangeHandler} value={lpNumber} />
                </div>
              }
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Disclaimer</span>
                </label>
                <Input type="text" value={warningMessageFilter} onChange={warningMessageChangeHandler} />

              </div>
            </div>

            <label className="ml-2"><b>Upload Survey Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setSurveyImages} defaultValues={surveyImages} />
            <label className="ml-2"><b>Upload Passbook Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setPassbookImages} defaultValues={passbookImages} />
            <label className="ml-2"><b>{stateSpecifics.landExtractsTitle}</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setLandExtractsImages} defaultValues={landExtractsImages} />
          </div>

          <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans border-2 border-solid rounded-xl border-[#ffde59]">

            <div className="form-control w-full max-w-xs">
              <div className="flex items-center">
                <Checkbox color="primary" value={isPhsyicallyVerifiedFilter} onChange={isPhsyicallyVerifiedChangeHandler} checked={isPhsyicallyVerifiedFilter} />
                <label className="ml-2 text-sm">Physical Verification Completed</label>
              </div>
            </div>

            <div className="form-control w-full max-w-xs">
              <div className="flex items-center">
                <Checkbox color="primary" value={isChancePropertyFilter} onChange={isChancePropertyChangeHandler} checked={isChancePropertyFilter} />
                <label className="ml-2 text-sm">Chance Property</label>
              </div>
            </div>

            <div className="form-control w-full max-w-xs">
              <SelectDropdownComponent labelName={'Status'} options={statusOptions} currValue={statusFilter} onChangeHandler={statusChangeHandler} />
            </div>
            <div className="form-control w-full max-w-xs">
              <SelectDropdownComponent labelName={'Exposure Type'} options={exposureTypeOptions} currValue={exposureTypeFilter} onChangeHandler={exposureTypeChangeHandler} />
            </div>

            {/*                     
                        <div className="flex flex-wrap gap-2">
                            {tags.map((tag, index) => (
                                        <div key={index} className="flex items-center gap-2">
                            <div
                            key={index}
                            className="bg-blue-500 text-white rounded-full px-3 py-1"
                            >
                            {tag}
                            </div>
                            </div>

  
                            
                        ))}
                        </div> */}

          </div>
          <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <label className="text-sm"><b>Land Bookmark</b></label>
                  </div>

                </div>
              </div>



              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={oneAcrePremium} onChange={oneAcrePremiumChangeHandler} checked={oneAcrePremium} />
                    <label className="ml-2 text-sm">Suitable for One Acre Premium</label>
                  </div>

                </div>
              </div>

              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={chance} onChange={chanceChangeHandler} checked={chance} />
                    <label className="ml-2 text-sm">Suitable for Chance</label>
                  </div>

                </div>
              </div>

              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={siteVerification} onChange={siteVerificationChangeHandler} checked={siteVerification} />
                    <label className="ml-2 text-sm">Suitable for Site Verification</label>
                  </div>

                </div>
              </div>

              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={investors} onChange={investorsChangeHandler} checked={investors} />
                    <label className="ml-2 text-sm">Suitable for Investors</label>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        {addPage
          ? (
            <div className="flex w-full items-center justify-center pt-10 pb-2">
              <Button color="primary" className="w-2/5" onClick={landSubmitHandler}>Add Land</Button>
            </div>

          ) : (
            <div className="flex w-full items-center justify-center  pt-10 pb-2">
              <Button color="primary" className="w-2/5" onClick={landSubmitHandler}>Update Land</Button>
            </div>

          )}
        {errorMessage && (
          <div className="flex w-full items-center justify-center">
            <label className="label text-red-500 text-sm">{errorMessage}</label>
          </div>
        )}
      </div>
      {notification.show && <NotificationComponent message={notification.message} type={notification.type} />}
    </div >
  )
}